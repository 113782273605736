import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import TwoColumns from '../components/twocolumns'

const AboutPage = ({data}) => {
  return (
    <Layout pageTitle="About Us">
      <TwoColumns content={data.allPagesJson} />
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    allPagesJson(filter: {title: {in: ["What We Do", "The Environment"]}}) {
      edges {
        node {
          title
          text
          photo {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 80
                  blurredOptions: {width: 100}
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                  formats: WEBP
                  width: 960
                  layout: CONSTRAINED
                )
              }
          }
        }
      }
    }
  }
`

export default AboutPage