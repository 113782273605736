import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Separator from "./separator"
import {
    twoColumnsContainer,
    buttons
} from'./lib/twocolumns.module.css'

const TwoColumns = ({content}) => {
    return (
        <div>
            {content.edges.map(edge => (
                <div key={edge.id} className={twoColumnsContainer} id={edge.node.anchor}>
                  <div>
                    <h2>{edge.node.title}</h2>
                    <p>{edge.node.text}</p>
                    <div className={buttons}>
                      <a href={`mailto:${edge.node.emailandsubject}`} target='_blank' rel='noreferrer'>{edge.node.emailtext}</a>
                      <a href={edge.node.pdfdownload} target='_blank' rel='noreferrer'>{edge.node.pdfdownloadtext}</a>
                    </div>
                  </div>
                  <div>
                    <GatsbyImage image={getImage(edge.node.photo)} alt={edge.node.title} />
                  </div>
                </div>
            ))}
            <Separator />
        </div>
    )
  }
  
export default TwoColumns