import React from "react";
import {
    divider,
    divTransparent,
    divArrowDown
} from'./lib/separator.module.css'

const Separator = () => {
    const arr = [divider, divTransparent, divArrowDown]
    return (
        <div>
	        <div className={arr.join(" ")}></div>
        </div>
    )
  }
  
export default Separator